import React, { useState, useEffect } from "react"
// import axios from "axios"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import ReqCur from "../../components/reqCur"

import { StripeProvider } from "react-stripe-elements"
import { Stripe } from "stripe.js"
import Checkout from "../../components/checkout"
import Instalments from '../../components/instalments.js'

import Contacts from "../../components/contactform"
import Covid from "../../components/covid"

// import css from '../../pics/responsive_web_design_with_css3_barcelona_code_school.png'

const CSScourseOnline = () => {
	let banner =
	"https://barcelonacodeschool.com/files/pics/css_online_course_banner.png"

	return (
		<div className="flexContent">
		<Helmet
		title="Responsive web design with CSS3 Online Course"
		meta={[
			{
				name: "description",
				content:
				"Responsive web design with CSS online course. Learn how to build modern websites which work great on every user device. 2-week course, part-time, self-paced.",
			},
			{
				name: "keywords",
				content:
				"responsive web design with css course, css3 course, learn css",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
			]}
			script={[{ src: "https://js.stripe.com/v3/" }]}
			/>
			<h1 className='transparentContainer flex1200'>Responsive web design with CSS3 <span className="highlighter">online</span> course</h1>
			

			<div className="fulltimeOnlineBanner flex1200">
			<img
			style={{ width: "99%" }}
			className="banners offsetImage"
			src={banner}
			alt="Responsive web design with CSS3 course in Barcelona Code School"
			/>
			<p className="stamp">ONLINE</p>
			</div>



			<h2 className='transparentContainer flex450'>Learn how to create modern responsive websites for any devices with the latest CSS</h2>

			<h2 className='transparentContainer flex225'>2 weeks, part-time, in English, online</h2>



			<article className="transparentContainer flex450">
			<h2>What you'll learn</h2>
			<div className='whiteCard'>
			<ul>
			<li>Creating all kinds of layout of any complexity</li>
			<li>
			Using media queries to switch between
			desktop/tablet/mobile versions of your website
			</li>
			<li>Understanding simple and combined selectors</li>
			<li>
			Absolute/relative length units (the good, the bad and
				the ugly)
			</li>
			<li>The box model</li>
			<li>
			Positioning, stacking, floating your elements
			with/wthout taking them out of the natural document's
			flow
			</li>
			<li>Using margins/paddings/borders</li>
			<li>Creating layouts with CSS Grid</li>
			<li>Using Flexbox</li>
			<li>
			Cross-browser testing, resetting, normalizing,
			debugging, validating
			</li>
			<li>Browser-based development</li>
			</ul>
			</div>
			</article>

			<article className="transparentContainer flex300">
			<h2>The outcomes</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			You will be able to build a layout for any kind of
			website
			</li>
			<li>
			It would be a responsive layout working great on any
			screen size
			</li>
			<li>
			A portfolio of several projects created with pure CSS
			</li>
			</ul>
			</div>
			</article>

			<article className='transparentContainer flex225'>
			<h2>Tuition</h2>
			<div className='whiteCard'>
			<h2>650€</h2>
			<p>Sign up online and reserve your seat.</p>
			<Instalments/>
			</div>
			</article>


			<article className="transparentContainer flex450">
			<h2>How it works</h2>
			<div className='whiteCard'>
			<ul>
			<li>Sign up through this page</li>
			<li>Fill out student's form we will send you</li>
			<li>
			Get the materials: text lessons, more than 6 hours of video
			lessons, 8 cloning projects for practicing
			</li>
			<li>Start learning</li>
			<li>
			Access support platform 24/7 to post code-related questions
			and get help
			</li>
			<li>
			Book your personal 1:1 sessions (2 sessions are included)
			</li>
			<li>
			Go through the lectures and complete exercises for each
			block of the curriculum
			</li>
			<li>Push your code to GitLab to get feedback/review from us</li>
			<li>Get yourt certificate once finished</li>
			</ul>
			</div>
			</article>

			<article className="transparentContainer flex900 flexContent">
			<h2>
			Access
			<span
			id="cohorts"
			style={{
				position: "relative",
				top: "-50",
				visibility: "hidden",
			}}
			>
			.
			</span>
			</h2>
			<div>
			<div>
			<div className="whiteCard">
			<span>
			<h2>
			Responsive web design with CSS3{" "}
			<span className="highlighter">Online</span>{" "}
			course
			</h2>
			<p>Online, part-time, 2 weeks </p>
			</span>
			<span>
			<Link
			to="/register"
			state={{
				course:
				"Responsive web design with CSS3 online course",
				date: new Date().toLocaleDateString(),
				cost: 650,
				images: [
				"https://barcelonacodeschool.com/files/pics/css_online_course_banner.png",
				],
			}}
			>
			<button style={{ marginBottom: "1em" }}>
			Sign up
			</button>
			</Link>
			<p className="finePrint">
			By signing up you agree to the{" "}
			<a href="/about-us/terms-and-conditions/">
			Terms and Conditions
			</a>{" "}
			stated on the website.
			</p>
			</span>
			</div>
			</div>
			</div>
			</article>

			<article className="transparentContainer flex300">
			<h2>Prerequisites</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			You will need a laptop, the OS doesn't matter, MacOS,
			Windows, Linux is all fine
			</li>
			<li>Google Chrome</li>
			<li>
			Any code editor: Sublime Text, Visual Studio Code, Atom,
			etc...
			</li>
			<li>An account with GitLab/GitHub (free account is fine!)</li>
			<li>
			If you can install Git it would be amazing.
			(Instructions for{" "}
				<a href="https://git-scm.com/book/en/v2/Getting-Started-Installing-Git">
				Mac
				</a>
				, <a href="https://gitforwindows.org/">Windows</a>,{" "}
				<a href="https://git-scm.com/book/en/v2/Getting-Started-Installing-Git">
				Linux
				</a>
				)
			</li>
			</ul>
			</div>
			</article>

			<article className="transparentContainer flex300">
			<h2>Who is this course for?</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			Students thinking about building their static websites,
			landing pages, prototypes
			</li>
			<li>
			Developers aiming at learning CSS to add to their skill
			set
			</li>
			<li>
			Designers willing to learn how to implement mockups in
			code
			</li>
			<li>Beginners starting to learn HTML/CSS</li>
			</ul>
			</div>
			</article>

			<article className='flex450'>
			<Contacts
			title={"Inquiry about the course"}
			subject={"Inquiry about CSS3 course"}
			/>
			</article>

			</div>
			)
}

export default CSScourseOnline
